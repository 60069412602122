// ---------------------------------------------------------------
// PERSISTENCE
// - cookies cannot handle more than 4096 bytes!
// - cookie and expiration with minimum footprint
//   cf. https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions
// - using gmapsStore here would make some functions disappear in the cached version
// - avoid localstorage: no expiration
// ---------------------------------------------------------------
import Vue from 'vue'
import Vuex from 'vuex'
// import Cookies from 'js-cookie'
// import createPersistedState from 'vuex-persistedstate'

import appStore from './modules/appStore';
import adminStore from './modules/adminStore';
import bankStore from './modules/bankStore';
import billingStore from './modules/billingStore';
import chatStore from './modules/chatStore';
import commsStore from './modules/commsStore';
import debugStore from './modules/debugStore';
import eventStore from './modules/eventStore';
import financeStore from './modules/financeStore';
import productsStore from './modules/productsStore';
import gmapsStore from './modules/gmapsStore';
import ratingStore from './modules/ratingStore';
import receiverStore from './modules/receiverStore';
import resultStore from './modules/resultStore';
import saasStore from './modules/saasStore';
import spaceStore from './modules/spaceStore';
import settingsStore from './modules/settingsStore';
import surveyStore from './modules/surveyStore';
import termsOfUseStore from './modules/termsOfUseStore';
import timeslotStore from './modules/timeslotStore';
import userStore from './modules/userStore';
import validateUserStore from './modules/validateUserStore';
import subscriptionsStore from './modules/subscriptionsStore';
import marketplaceStore from './modules/marketplaceStore';

Vue.use(Vuex);

// const isDev = process.env.NODE_ENV ? process.env.NODE_ENV === 'development' : true

// 1/12 of a day, or 2 hours
// const cookieParams = { sameSite: 'lax', expires: 1 / 12, secure: !isDev }
// const persistPluginCookie = createPersistedState({
//   key: '__habitat_cache_cookie',
//   paths: ['user.name'],
//   storage: {
//     getItem: key => Cookies.get(key),
//     setItem: (key, value) => Cookies.set(key, value, cookieParams),
//     removeItem: key => Cookies.remove(key, cookieParams), // cookieParams are needed for removal
//   }
// })

// const persistPluginSessionStorage = createPersistedState({
//   storage: window.sessionStorage,
//   key: '__habitat_cache_session',
//   paths: [
//     'specialization.specializations',
//     'language.allLanguages',
//     'healthplan.allHealthplans',
//   ],
// })

// import SecureLS from 'secure-ls'
// const ls = new SecureLS({ isCompression: true })
// const persistPluginEncryptedSessionStorage = createPersistedState({
//   key: '__habitat_cache_encrypted_ls',
//   paths: ['specialization.specializations'],
//   storage: {
//     getItem: (key) => ls.get(key),
//     setItem: (key, value) => ls.set(key, value),
//     removeItem: (key) => ls.remove(key),
//   },
// })

export default new Vuex.Store({
  modules: {
    app: appStore,
    admin: adminStore,
    bank: bankStore,
    billing: billingStore,
    chat: chatStore,
    comms: commsStore,
    debug: debugStore,
    event: eventStore,
    finance: financeStore,
    gmaps: gmapsStore,
    products: productsStore,
    ratings: ratingStore,
    receiver: receiverStore,
    result: resultStore,
    saas: saasStore,
    space: spaceStore,
    settings: settingsStore,
    surveys: surveyStore,
    termsOfUse: termsOfUseStore,
    timeslot: timeslotStore,
    user: userStore,
    validateUser: validateUserStore,
    subscription: subscriptionsStore,
    marketplace: marketplaceStore,
  },
  plugins: [
    // persistPluginCookie,
    // persistPluginSessionStorage
  ],
})
