export default {
  'en-us': {
    home: 'Homepage',
    help: 'Help',
    login: 'Login',
    logout: 'Logout',
  },

  'pt-br': {
    home: 'Início',
    help: 'Ajuda',
    error_401: 'Usuário inexistente ou senha incorreta',
    login: 'Conectar',
    logout: 'Sair',
    check: 'Cheque',
    money: 'Em espécie',
    credit: 'Cartão de crédito',
    debit: 'Cartão de débito',
    'auth/invalid-login-credentials': 'Usuário ou senha incorretos',
    'auth/user-not-found': 'Usuário ou senha incorretos',
    'auth/wrong-password': 'Usuário ou senha incorretos',
    'auth/email-already-in-use': 'E-mail já em uso. Use o espaço de login para se conectar',
    'auth/email-already-exists': 'E-mail já em uso',
    'auth/popup-closed-by-user': 'Popup de login fechado pelo usuário',
    'auth/popup-blocked': 'Popup de login bloqueado pelo navegador. Por favor desbloqueie e tente novamente',
    success: {
      addressFound: 'Endereço encontrado!',
      userValidated: 'Usuario validado.',
      eventAdded: 'Reserva adicionada',
      eventUpdated: 'Evento alterado',
      visitScheduled: 'Visita marcada',
      dataUpdated: 'Dados atualizados com sucesso',
      dataExported: 'Excel exportado com sucesso. Favor ignorar eventuais popups do Excel ao abrir o arquivo.',
      mainBankSet: 'Banco principal definido com sucesso',
      uploadCompleted: 'Upload completo.',
      hourPackageUpdated: 'Pacote de Horas atualizado',
      messageSent: 'Mensagem enviada',
      requestSent: 'Solicitação enviada',
      creditCardAdded: 'Cartão adicionado',
      receiverAdded: 'Recebedor adicionado',
      transferSent: 'Repasse enviado',
      successfulOperation: 'Operação realizada com sucesso',
      successfulValidation: 'Validação concluída',
      newPasswordLinkSent: 'Enviamos um e-mail contendo o link para a redefinição de sua senha. Favor verificar sua caixa de spam e esperar 5 minutos antes de uma nova solicitação.',
      subscriptionCanceled: 'Assinatura cancelada',
      subscriptionUpdated: 'Assinatura atualizada',
      paymentUpdated: 'Pagamento atualizado',
      transactionCreated: 'Cobrança criada',
      productAdded: 'Produto adicionado com sucesso',
      successfulPurchase: 'Compra realizada com sucesso!',
      servicesRequested: 'Serviços solicitados com sucesso',
      eventExtended: 'Reserva estendida',
      doctorReviewed: 'Habitante avaliado com sucesso',
      paymentConfirmed: 'Pagamento confirmado',
      waitingAnalysis: 'Dados enviados. Aguarde análise.',
      receiverRemoved: 'Recebedor removido',
      transferMade: 'Transferência realizada',
      modificationsSaved: 'Modificações salvas',
      checkOutUpdated: 'Check-out atualizado',
      serviceRequestApproved: 'Solicitação aprovada',
      photoRemoved: 'Foto removida.',
      emailUpdated: 'Seu email foi atualizado. Refaça o login',
      notificationsAuthorized: 'Notificações autorizadas',
      couponAdded: 'Cupom cadastrado com sucesso',
      couponCanceled: 'Cupom cancelado',
      habitatPassAndLevelSaved: 'Nível e Habitat Pass salvos',
      habitatPassRejected: 'Habitat Pass reprovado',
      comissionUpdated: 'Comissão alterada',
      spaceEnabled: 'Consultório habilitado',
      spaceDisabled: 'Consultório desabilitado',
      userEnabled: 'Habitante habilitado',
      userDisabled: 'Habitante desabilitado',
      userRegistered: 'Usuário cadastrado com sucesso',
      usersImported: 'Habitantes importados com sucesso',
      doctorValidated: 'Usuário aprovado, portanto foi removido desta lista',
      doctorDocumentsRejected: 'Documentos rejeitados.',
      advertisementApproved: 'Anúncio aprovado e publicado',
      advertisementRejected: 'Anúncio rejeitado.',
      changesSaved: 'Alterações salvas',
      eventsMovedOneDayInTheFuture: 'Reservas movidas um dia no futuro',
      eventsMovedOneDayInThePast: 'Reservas movidas um dia no passado',
      concluded: 'Concluído',
      habitatPassUpdated: 'Habitat Pass atualizado',
      newSpecialization: 'Nova especialização adicionada',
      specializationRemoved: 'Especialização removida',
      tokenCopied: 'Token copiado para a área de transferência',
      spaceReviewed: 'Consultório avaliado com sucesso',
      addressAdded: 'Endereço adicionado com sucesso',
      removedAddress: 'Endereço removido com sucesso',
      cardAdded: 'Cartão adicionado com sucesso',
      cardRemoved: 'Cartão removido com sucesso',
      updated: 'Atualizado',
      accessApproved: 'Acesso aprovado',
      resentEmailVerification: 'Email de verificação reenviado!',
      saasAccessRequestSent: 'Solicitação enviada com sucesso!',
      hourPackageCanceled: 'Pacote de horas cancelado com sucesso!',
      linkCopied: 'Link copiado para a área de transferência',
      audioRecorded: 'Áudio gravado com sucesso!'
    },
    info: {
      eventDeleted: 'Evento removido com sucesso',
      addressNotFound: 'Não conseguimos encontrar a rua. Por favor preencha manualmente',
      exportNotFound: 'Nenhum dado a ser exportado.',
      uploading: 'Fazendo upload de arquivos...',
      eventCanceled: 'Reserva cancelada',
      eventNotInProgress: 'Não há reserva em andamento',
      dataNotCompleted: 'Alguns dados estão faltando. Por favor preencha corretamente',
      loginNotInSaas: 'Este não é um ambiente SaaS. Por favor faça login no endereco correto',
      transferInProgress: 'Transferência em andamento',
      transactionSent: 'Cobrança enviada',
      readonlyCalendar: 'Este calendário serve apenas para indicar os horários disponíveis. Favor inserir o horários desejados nos campos apropriados.',
      verifyCodeInMail: 'Por favor, verifique o código enviado no seu email',
      paymentRemoved: 'Pagamento removido',
      purchaseRefused: 'Compra recusada!',
      saveInProgress: 'Salvando dados. Aguarde',
      bankRemoved: 'Banco removido com sucesso',
      receiverRemoved: 'Recebedor removido com sucesso',
      habitatPassCanceled: 'Habitat Pass cancelado',
      habitatPassUpdated: 'Habitat Pass atualizado',
      hourPackageCanceled: 'Pacote de Horas cancelado',
      integralProductCanceled: 'Habitat Próprio cancelado',
      monthlyProductCanceled: 'Habitat Fixo cancelado',
      advertisementPaused: 'Anúncio pausado',
      advertisementPublished: 'Anúncio publicado',
      pendingAdvertisement: 'Seu anúncio será aprovado pela equipe do Habitat. Assim que houver uma atualização você receberá um email',
      changeCanceled: 'Alterações canceladas',
      serviceRequestRejected: 'Solicitação rejeitada',
      mainPhotoRemoved: 'Você removeu sua foto de capa. Favor selecionar outra foto.',
      uploadingImage: 'Fazendo upload de imagens...',
      imagesSaved: 'Fotos salvas com sucesso',
      editClickTip: 'Clique em editar para alterar as informações',
      serviceRemoved: 'Serviço removido',
      adminActived: 'Administrador ativado',
      couponUpdated: 'Cupom editado',
      selectReport: 'Selecione um relatório',
      downloadingFile: 'Baixando arquivo...',
      emailVerified: 'Email verificado',
      emailVerificationRestarted: 'Verificação de email reiniciada',
      validating: 'Validando...',
      wait: 'Aguarde...',
      codeCopied: 'Código copiado',
      updatingPreference: 'Atualizando preferência',
      serviceAdd: 'Serviço adicionado com sucesso',
      accessRejected: 'Acesso rejeitado',
      subdomainRequired: 'Por favor, informe um subdomínio válido',
      creditCardRequired: 'Por favor, informe um cartão de crédito',
      subscriptionUpdated: 'Assinatura atualizada',
      subscriptionCanceld: 'Assinatura cancelada',
      linkCopied: 'Link copiado para a área de transferência',
      saasAccessRequestApproved: 'Sua solicitação já foi aprovada',
      saasAccessRequestPending: 'Sua solicitação está em análise',
      passwordUpdated: 'Senha alterada com sucesso!',
      uploadStarted: 'Upload do áudio iniciado!'
    },
    error: {
      eventTypeNotImplemented: 'Não implementado para esse tipo de evento. Favor entrar em contato com a Habitat.',
      defaultMessage: 'Tivemos um problema, tente novamente',
      selectUnity: 'Selecione uma unidade',
      selectSpace: 'Selecione um consultório',
      mainBankNotSet: 'Erro ao definir banco principal',
      pixNotSaved: 'Erro ao salvar chave PIX',
      pixNotRemoved: 'Erro ao remover chave PIX',
      spacesSearch: 'Erro ao buscar consultórios',
      rescheduleEvent: 'Esta reserva não pode ser reagendada/cancelada',
      rescheduleVisit: 'Visitas não podem ser reagendadas. Favor anular e marcar outra.',
      monthlyEventCancel: 'Plano integral/mensal não pode ser cancelado nesta página. Favor se direcionar a "Meus produtos".',
      pastCancelPeriod: 'Reservas só podem ser anuladas/reagendadas com 24 horas de antecedência.',
      onlyDoctorCanReschedule: 'Desculpe. Atualmente, apenas o profissional de saúde pode remarcar agendamentos.',
      operationNotSuccessfull: 'Não foi possível concluir a operação.',
      onlyDoctorCanCancel: 'Desculpe. Atualmente, apenas o profissional de saúde pode anular agendamentos que ele realizou.',
      eventServicesSearch: 'Erro ao buscar serviços do evento',
      checkInBeforeCheckOut: 'Check-in previsa ser anterior ao check-out.',
      checkOutRequired: 'Preencha o check-out',
      invalidCheckOut: 'O novo check-out deve ser posterior ao antigo',
      selectRecipient: 'Favor selecionar um destinatário.',
      uploadFailed: 'Desculpe, ocorreu um problema ao tentar enviar os arquivos',
      monthDigits: 'O mês deve ter dois dígitos',
      yearDigits: 'O ano deve ter dois dígitos',
      dateExpiration: 'Data de validade deve ser maior ou igual do que a data atual',
      visitDayConfirmation: 'Você só pode confirmar visitas no dia da visita',
      selectAddress: 'Favor selectionar um endereço entre as proposições',
      locationEmpty: 'Localidade é campo obrigatório.',
      selectTemplate: 'Selecione uma mensagem',
      invalidFields: 'Por favor preencha corretamente os campos',
      selectAddressOption: 'Selecione uma das opções de endereço',
      selectSavedAddress: 'Selecione um dos endereços salvos',
      conflictingSlot: 'Este horário está indisponível ou ocupado por outras reservas',
      termsOfUseRequired: 'Favor aceitar os Termos e Condições de Uso.',
      differentPasswords: 'Senhas inseridas são diferentes.',
      verificationCodeRequired: 'Por favor insire o código de verificação',
      formFieldsRequired: 'Por favor, preencha os campos do formulário',
      cpfCnpjRequired: 'Por favor, preencha o campo de CPF ou de CNPJ',
      fileSize: 'Arquivos em formato incorreto ou acima de 10Mb',
      rgCpfRequired: 'Rg ou CPF é obrigatório',
      frontDocumentRequired: 'Envie também a frente do documento',
      emailAndPasswordRequired: 'Email e senha são obrigatórios para o login.',
      invalidEmail: 'E-mail inválido',
      emailNotFound: 'E-mail não encontrado',
      unableToLoadSurvey: 'Não foi possível carregar a pesquisa',
      acceptTerms: 'Erro ao aceitar os termos. Tente novamente ou contate o suporte',
      selectProduct: 'Selecione um produto',
      valueRequired: 'Informe o valor',
      expireDateRequired: 'Informe a data de vencimento',
      selectPaymentMethod: 'Selecione o método de pagamento',
      selectCreditCard: 'Selecione o cartão de crédito',
      descriptionRequired: 'Informe a descrição',
      allFieldsRequired: 'Preencha corretamente os campos',
      selectHourPackage: 'Selecione as horas do pacote',
      selectHabitatPass: 'Selecione o Habitat Pass',
      selectHabitatPassHour: 'Selecione as horas do Habitat Pass',
      selectContractStart: 'Selecione o início do contrato',
      selectOrAddPaymentMethod: 'Selecione ou adicione um método de pagamento',
      documentRequired: 'Preencha o documento',
      documentSave: 'Erro ao salvar documento',
      doctorDocumentsValidation: 'Erro ao validar documentos.',
      doctorUserValidation: 'Erro ao validar usuário',
      eventLoad: 'Erro ao carregar detalhes da reserva',
      reasonRequired: 'O motivo é obrigatório',
      unauthorized: 'Acesso não autorizado',
      educationLevelRequired: 'Preencha seu nível de formação',
      specializationRequired: 'Preencha suas especialidades',
      fileEmpty: 'Nenhum arquivo para enviar',
      selectTransfer: 'Selecione pelo menos um repasse',
      operationTypeRequired: 'Selecione o tipo da transferência',
      pixTypeRequired: 'Selecione o tipo da chave Pix',
      pixKeyRequired: 'Informe a chave Pix',
      selectBank: 'Selecione a conta bancária',
      transferValueInvalid: 'Valor da transferência deve ser maior que zero',
      insufficientBalanceForTransfer: 'Saldo para transferência insuficiente',
      selectNewHabitatPass: 'Selecione o novo Habitat Pass',
      idNotFound: 'Campo id não encontrado',
      spaceWidthRequired: 'Preencha a largura do consultório',
      spaceLengthRequired: 'Preencha o comprimento do consultório',
      unitiesLoad: 'Erro ao carregar unidades',
      addressRequired: 'Pelo menos um campo de endereço obrigatório não foi preenchido',
      spaceNameRequired: 'Favor adicionar um nome para a identificação deste consultório',
      zipNotFound: 'Desculpe, não foi possível encontrar este CEP. Favor preencher manualmente o formulário.',
      gmapAddressNotFound: 'Endereço não encontrado no Google Maps',
      geoLocationNotFound: 'Desculpe, não foi possível detectar a geolocalização neste momento.',
      receiversLoad: 'Erro ao carregar recebedores',
      problematicShare: 'A divisão total deve ser igual a 100%',
      problematicBusinessHours: 'O horário de abertura precisa preceder o de fechamento',
      minAdvertisementPhotos: 'É preciso no mínimo duas fotos para o anúncio',
      iptuRequired: 'O Registro do IPTU não foi preenchido',
      invalidSpaceHours: 'Preencha corretamente os horários do consultório',
      spaceAvailabilityRequired: 'Preencha pelo menos uma disponibilidades do consultório',
      spaceProductRequired: 'Preencha pelo menos um produto disponível no consultório',
      spaceFinanceRequired: 'Preencha o financeiro do consultório',
      invalidHourPackageValues: 'Preencha os valores do produto Pacote de Horas',
      newEmailMustBeDifferent: 'Novo email deve ser diferente do atual',
      emailsMustBeEqual: 'Email e confirmação não são iguais',
      accountRemoved: 'Sua conta foi removida',
      selectSpecialization: 'A especialização não está selecionada',
      monthlyHoursRequired: 'Preencha corretamente as horas mensais',
      negativeHours: 'Horas não podem ser negativas',
      selectRenewOption: 'Escolha uma das opções acima',
      invalidHours: 'Informe um número de horas válido',
      extraHoursNotEnough: 'Horas extras não são o suficiente para reserva',
      selectContractPeriod: 'Selecione o tempo de contrato',
      invalidEventSlot: 'Adicione um ou mais horários para reservar',
      selectPackage: 'Pacote não selecionado',
      userNotFound: 'Usuário não encontrado. Favor contatar o suporte.',
      marketplaceNotFound: 'Marketplace não encontrado. Favor contatar o suporte',
      habitatPassLevelRequired: 'Selecione um nível para o consultório',
      habitatPassLevelSave: 'Erro ao salvar o Nível de Habitat Pass',
      habitatPassReject: 'Erro ao reprovar Habitat Pass',
      exportReport: 'Erro ao exportar relatório',
      commissionRequired: 'Infome a comissão',
      downloadFile: 'Erro ao fazer download do arquivo. Tente novamente ou contate o suporte.',
      importUsers: 'Problema ao importar habitantes',
      doctorDocumentsInvalidation: 'Erro ao invalidar documentos',
      advertisementInvalidation: 'Erro ao invalidar anúncio',
      advertisementValidation: 'Erro ao validar anúncio',
      saasMonthlySubscriptionRequired: 'Mensalidade Saas deve ser preenchida e maior que zero',
      saasYearlySubscriptionRequired: 'Anuidade Saas dever ser preenchida e maior que zero',
      saasPercentageRequired: 'Porcentagem do SaaS deve ser preenchida e maior que zero',
      saasPercentageMonthlyRequired: 'Porcentagem do SaaS anual deve ser preenchida e maior que zero',
      habitatPassNamesRequired: 'Os nomes devem estar preenchidos',
      habitatPassMinHoursRequired: 'O mínimo de horas devem estar preenchidos, serem maiores que zero e inteiros',
      habitatPassPricesRequired: 'Os preços devem estar preenchidos e serem maiores que zero',
      habitatPassTransfersRequired: 'Os repasses devem estar preenchidos, serem maiores que zero e menores que o preço total',
      habitatPassColorsRequired: 'As cores devem estar preenchidos',
      specializationNameRequired: 'Preencha o nome da especialização',
      transactionFineRequired: 'Multa deve ser preenchida e maior que zero',
      transactionInterestRequired: 'Juros deve ser preenchido e maior que zero',
      receiversMinCount: 'Limite de recebedores deve ser maior que zero',
      pdfRequired: 'O documento PDF é obrigatório',
      paramsNotFound: 'Parâmetros obrigatórios não encontrados',
      invalidParams: 'Parâmetros inválidos',
      dataNotFound: 'Dados não encontrados',
      updatingData: 'Erro ao atualizar dados',
      loadingTerms: 'Erro ao carregar termos',
      ratingOneToFiveRequired: 'Escolha uma nota de 1 a 5 para todos os itens',
      spaceNotFound: 'O consultório procurado foi retirado ou não existe',
      selectVisitType: 'Favor selecionar o tipo de visita',
      visitDateRequired: 'Favor preencher a data da visita',
      selectSpec: 'Favor selecionar pelo menos uma especialidade',
      scheduleNotFound: 'Agendamento não encontrado!',
      balanceLoad: 'Erro ao buscar balanço',
      emailExists: 'Email já cadastrado',
      addUser: 'Problema ao adicionar o usuário',
      termsFileRequired: 'Por favor, adicione um arquivo de termo de aceite',
      logoRequired: 'Logo é obrigatória',
      advertisingTermsRequired: 'Favor confirmar os direitos para divulgação do espaço na plataforma.',
      emailParamRequired: 'Nenhum email passado como parâmetro: você digitou esse endereço manualmente?',
      spaceLocationNotFound: 'Localização do consultório não pôde ser encontrada.',
      saasAccessRequestRejected: 'Sua solicitação foi rejeitada',
      currentPasswordRequired: 'Preencha a senha atual',
      newPasswordRequired: 'Preencha a nova senha',
      passwordMustBeDifferent: 'A nova senha deve ser diferente da senha atual',
      passwordsNotMatching: 'As senhas não conferem',
      confirmNewPassword: 'Confirme a senha nova',
      emailVerificationRequired: 'Favor confirmar sua conta clicando no link que enviamos para seu email.',
      server: 'Internal server error',
      params: {
        not: {
          found: 'Parâmetros obrigatórios não encontrados',
        },
        missing: {
          event: 'Nenhum evento foi passado.',
          user: {
            name: 'Nome é obrigatório',
            email: 'E-mail é obrigatório',
            phone: 'Telefone é obrigatório',
            birthday: 'Data de nascimento é obrigatório',
            card: 'Cartão é obrigatório',
            password: 'Senha é obrigatória',
            documents: 'CPF ou CNPJ é obrigatório',
            gender: 'Sexo é obrigatório',
            specializations: 'Especializações são obrigatórias'
          },
          email: 'E-mail não informado',
          message: 'Mensagem não informada',
          recipient: 'Destinário não selecionado',
          coupon: {
            is: {
              required: 'Cupom é obrigatório',
            },
            code: 'Código é obrigatório',
            discount: {
              type: 'Tipo de Desconto é obrigatório',
              value: 'Valor do desconto é obrigatório',
              more: 'Valor deve ser maior que zero',
            },
            duration: {
              test: 'Duração do teste é obrigatória',
              more: 'A duração deve ser maior que 0',
            }
          },
          file: 'Arquivo perdido',
          start: {
            date: 'Data inicial não informada.'
          },
          end: {
            date: 'Data final não informada.'
          },
          report: 'Tipo de exportação não encontrado.',
          year: 'Ano é parâmetro obrigatório',
          card: 'Dados do cartão não foram enviados',
          receiver: 'Recebedor não informado.',
          params: 'Erro ao marcar mensagem como lida.',
          contract: {
            start: 'Início do contrato não foi informado',
            time: 'O tempo de contrato não foi informado'
          },
          payment: {
            date: 'Data de pagamento não informada',
            method: 'Método de pagamento não informado',
          },
          habitat: {
            pass: 'Habitat Pass não informado',
          },
          fine: {
            value: 'Multa não é um valor válido',
          },
          interest: {
            value: 'Juros não é um valor válido'
          },
          receivers: {
            limit: {
              value: 'Limite de recebedores deve ser um valor válido',
            }
          },
          saas: {
            monthly: {
              value: 'Valor da mensalidade SaaS não é um valor válido',
              percentage: {
                value: 'Valor da porcentagem do SaaS não é um valor válido',
              }
            },
            yearly: { 
              value: 'Valor da anuidade SaaS não é um valor válido',
              percentage: {
                value: 'Valor da porcentagem do SaaS anual não é um valor válido',
              }
            },
          },
          hours: 'O número de horas não foi informado',
          slots: 'Os horários não foram informados'
        },
        commission: {
          too: {
            big: 'Comissão deve ser maior máximo 100%',
            small: 'Comissão deve ser maior que zero',
          },
          invalid: 'Comissão inválida',
        },
        export: {
          type: 'Tipo de exportação não informado.',
        },
        discount: {
          percentage: {
            invalid: 'Valor do desconto deve ser no máximo 100%'
          }
        },
        saas: {
          subdomain: 'Subdomínio é obrigatório',
          logo: 'Logo é obrigatório'
        }
      },
      audio: {
        requires: {
          permission: 'Permissões de microfone são necessárias para gravar.',
        },
        not: {
          in: {
            progress: 'Nenhuma gravação em andamento para interromper.'
          }
        },
        too: {
          small: 'O tamanho do conteúdo é muito pequeno. O áudio deve conter no mínimo 5 minutos!'
        },
        upload: 'Erro ao fazer upload do áudio'
      },
      access: {
        token: {
          invalid: 'Token de acesso inválido',
          inactive: 'Token de acesso inativo',
          not: {
            found: 'Token de acesso não encontrado'
          }
        }
      },
      address: {
        not: {
          found: 'Endereço não encontrado'
        }
      },
      authentication: {
        failed: 'Falha na autenticação'
      },
      subscription: {
        not: {
          found: 'Assinatura não encontrada',
        },
        create: 'Erro ao criar assinatura',
        could: {
          not: {
            cancel: {
              all: 'Nem todos os pacotes mensais puderam ser cancelados',
            }
          }
        }
      },
      unity: {
        not: {
          found: 'Unidade não encontrada na base de dados.',
          active: 'Assinatura não está ativa',
        },
        unavailable: 'No momento não é possível realizar reservas nesta unidade',
      },
      recurring: {
        event: {
          can: {
            not: {
              be: {
                canceled: 'Não é possível cancelar uma reserva recorrente'
              }
            }
          }
        }
      },
      event: {
        transaction: {
          pending: 'Aguarde a confirmação do pagamento da reserva original para poder remarcá-la',
        },
        not: {
          found: 'Reserva não encontrada',
          started: 'Reserva ainda não começou',
        },
        exit: {
          confirmed: 'Saída desta reserva já foi confirmada',
        },
        canceled: 'Reserva está cancelada',
        can: {
          not: {
            be: {
              canceled: 'Reservas só podem ser canceladas/reagendadas com 24 horas de antecedência'
            }
          }
        },
        date: {
          limit: 'Reservas podem ser feitas até 60 dias no futuro',
          past: 'Ao menos um dos horários selecionados começa no passado',
          start: {
            next: {
              future: 'A data de reserva deve iniciar pelo menos 24h no futuro',
            }
          }
        },
        out: {
          of: {
            space: {
              availability: 'O espaço não está aberto durante todo ou parte das reservas solicitadas.'
            }
          }
        }
      },
      space: {
        disabled: {
          on: {
            day: { 
              reservation: 'Neste consultório não é possível reservar no dia atual'
            }
          },
        },
        unavailable: 'No momento não é possível realizar reservas neste consultório',
        not: {
          found: 'Espaço não encontrado na base de dados.',
          available: 'O espaço não está aberto durante todo ou parte da reserva solicitada.',
          in: {
            unity: 'Consultório não pertence a unidade',
          },
          from: {
            saas: 'Consultório não percente ao Saas',
          }
        },
        missing: {
          default: {
            price: 'Consultório não definiu taxa de atraso'
          },
          receivers: 'Erro de recebimento do consultório. Favor entrar em contato com suporte'
        },
        availability: {
          removed: 'As disponibilidades deste consultório foram removidas.',
        },
        habitat: {
          pass: {
            not: {
              found: 'Habitat Pass do consultório não encontrado'
            }
          }
        },
        main: {
          hour: {
            package: {
              exists: 'Outro consultório já está compartilhando com todos'
            }
          }
        }
      },
      visit: {
        type: {
          not: {
            unauthorized: 'Tipo de visita não autorizado para este consultório.',
          }
        },
        not: {
          found: 'Visita não encontrada',
        },
        in: {
          the: {
            past: 'Visita já passou',
          }
        },
        canceled: 'Visita está cancelada',
      },
      verification: {
        code: {
          expired: 'O código expirou'
        }
      },
      adding: {
        card: 'Erro ao adicionar cartão'
      },
      admin: {
        must: {
          be: {
            use: {
              same: {
                email: 'Você precisa estar conectado com o mesmo email do convite recebido.',
              }
            }
          }
        }
      },
      generating: {
        link: 'Erro ao gerar link',
      },
      transaction: {
        reference: {
          not: {
            found: 'Referência da transação não encontrada'
          }
        },
        not: {
          found: 'Transação não encontrada',
          pending: 'Apenas transações pendentes ou atrasadas',
          confirmed: 'Transação não está confirmada'
        },
        can: {
          not: {
            be: {
              canceled: 'Transação não pode ser cancelada ou estornada',
              updated: 'Transação não pode ser atualizada',
              expired: 'Transação não pode ser expirada',
            }
          }
        },
        voucher: {
          not: {
            found: 'Comprovante não encontrado'
          }
        },
      },
      link: {
        not: {
          generated: 'Link não foi gerado.',
        }
      },
      uploading: {
        terms: 'Erro no upload do novo Termo de Uso. Tente novamente ou contat o suporte',
      },
      saas: {
        subscription: {
          is: {
            active: 'Assinatura já está ativa',
          },
        },
        user: {
          not: {
            found: 'Habitante não encontrado',
          },
          already: {
            exists: 'Usuário já existe neste Saas',
          },
          access: {
            not: {
              pending: 'Acesso não está pendente'
            }
          },
          request: {
            pending: 'Usuário já possui uma solicitação pendente'
          }
        },
        plan: {
          not: {
            found: 'Planos não encontrados',
          },
          invalid: 'Plano inválido'
        },
        not: {
          found: 'Saas não encontrado',
          active: 'Saas não está ativado',
        },
        can: {
          not: {
            add: {
              self: 'Você não pode adicionar a si mesmo'
            },
          }
        },
        create: 'Erro ao criar o SaaS',
        subdomain: {
          different: {
            user: 'Subdomínio não pertence ao usuário',
          },
          in: {
            use: 'Subdomínio já está em uso'
          },
          invalid: 'Subdomínio inválido',
          create: 'Erro ao criar subdomínio'
        },
        role: {
          invalid: 'Função inválida',
        },
        access: {
          request: {
            pending: 'A requisição está pendente',
            approved: 'A requisião já aprovada',
            rejected: 'A requisição rejeitada',
          },
          already: {
            requested: 'Requisição já está feita'
          }
        },
        service: {
          request: {
            not: {
              found: 'Requisição não encontrada',
              pending: 'Requisição já foi processada',
            }
          }
        },
        inactive: 'Este SaaS não está ativo',
      },
      invalid: {
        activation: {
          code:  'Código de ativação incorreto: link não é mais válido.',
        },
        action: 'Ação inválida',
        date: 'A data não é válida',
        value: 'Valor inválido',
        hours: 'Horas inválidas',
      },
      user: {
        cnpj: {
          in: {
            use: 'O CNPJ informado já está em uso',
          }
        },
        cpf: {
          in: {
            use: 'O CPF informado já está em uso',
          },
        },
        code: {
          verification: 'Código de verificação incorreto',
          expired: 'Código de verifação expirado ou já usado'
        },
        gender: {
          invalid: 'Sexo inválido',
        },
        already: {
          disabled: 'Usuário já está desabilitado',
          admin: 'Administrador já adicionado.',
          has: {
            habitat: {
              pass: 'Usuário já possui um Habitat Pass'
            }
          }
        },
        not: {
          found: 'Usuário não encontrado',
          unauthorized: 'Você não tem permissão para esta ação',
          connected: 'Usuário não está conectado',
          authorized: 'Somente o dono do produto pode acessá-lo',
          validated: 'Somente profissionais validados podem reservar neste consultório',
        },
        validation: {
          not: {
            found: 'Validação do usuário não encontrado na base de dados.',
          },
          invalid: {
            spec: {
              file: 'Todos os arquivos de especialidades devem estar preenchidos.',
            }
          }
        },
        is: {
          super: {
            admin: 'Esse usuário é um Super Administrador, e não pode (nem precisa) ser adicionado como Administrador.',
          }
        },
        email: {
          not: {
            found: 'Email não encontrado'
          },
          verified: 'Email já foi verificado',
          registered: 'E-mail já cadastrado',
        },
        data: {
          not: {
            found: 'Dados não encontrados'
          }
        },
        add: 'Erro ao adicionar usuário',
        can: {
          not: {
            purchase: 'Usuário não pode realizar compras',
          }
        },
        has: {
          debits: 'Usuário possui débitos pendentes'
        },
        exists: 'Usuário já cadastrado',
        create: 'Erro ao criar usuário',
      },
      updating: {
        user: {
          preferences: 'Não foi possível atualizar as preferências do usuário.'
        }
      },
      contact: {
        support: 'Tivemos um problema. Contate o suporte',
      },
      coupon: {
        invalid: 'Cupom inválido',
        all: {
          used: 'Cupom esgotado',
        },
        in: {
          use: 'Este cupom já está em uso',
        }
      },
      service: {
        not: {
          found: 'Serviço não encontrado',
        },
        disabled: 'Serviço não disponível na data selecionada',
        request: {
          canceled: 'Pedido já cancelado',
          not: {
            found: 'Pedido não encontrado'
          }
        },
        add: 'Erro ao adicionar serviços'
      },
      owner: {
        not: {
          found: 'Proprietário não encontrado na base de dados.'
        },
        space: {
          not: {
            found: 'Dono do espaço não encontrado na base de dados.'
          }
        }
      },
      habitat: {
        pass: {
          not: {
            found: 'Não há Habitat Pass para o nível selecionado.',
          },
          level: {
            required: 'Seu nível de Habitat Pass é mais baixo do que o requisitado neste consultório'
          },
          active: {
            not: {
              found: 'Nenhum pacote Habitat Pass ativo encontrado'
            }
          },
          package: {
            without: {
              active: {
                period: 'Não há período ativo'
              }
            },
            transaction: {
              pending: 'Seu pacote Habitat Pass está em processo de compra',
            },
            empty: 'Suas horas do Habitat Pass neste mês se esgotaram',
            not: {
              found: 'Pacote Habitat Pass não encontrado',
              active: 'Usuário não possui Habitat Pass ativo'
            },
            canceled: 'O produto já está cancelado',
            renew: {
              option: {
                not: {
                  found: 'Opção de renovação não encontrada',
                },
                required: 'Escolha uma opção para renovar o pacote'
              }
            },
            extra: {
              hours: {
                required: 'Informe as horas extras do pacote',
              }
            },
            invalid: {
              extra: {
                hours: 'Horas extras não são o suficiente para a reserva',
              }
            }
          },
          invalid: {
            space: 'Este consultório não possui Habitat Pass ativo'
          },
          space: {
            not: {
              found: 'Habitat Pass do consultório não encontrado'
            }
          },
          can: {
            not: {
              be: {
                canceled: 'Habitat Pass não pode ser cancelado'
              }
            }
          }
        }
      },
      invitation: {
        revoked: 'Convite foi revogado.',
      },
      bank: {
        not: {
          found: 'Banco não encontrado'
        },
        removed: 'Erro ao delete banco. Tente novamente',
        main: {
          updated: 'Erro ao atualizar banco principal'
        },
        slip: {
          url: {
            not: {
              found: 'Link do boleto não encontrado'
            }
          }
        },
        transfer: {
          limit: 'Valor máximo de transferência excedido, entrar em contato com o suporte'
        }
      },
      card: {
        not: {
          found: 'Cartão não encontrado'
        }
      },
      debit: {
        not: {
          found: 'Débito não encontrado'
        }
      },
      hour: {
        package: {
          negative: {
            hours: 'Saldo de horas negativo. Contate o suporte'
          },
          payment: {
            pending: 'Você está tentando usar um pacote com pagamento pendente. Aguarde antes de fazer novas reservas'
          },
          in: {
            use: 'O pacote está em uso e não pode ser cancelado',
          },
          not: {
            in: {
              use: 'O pacote não pode ser usado'
            },
            found: 'Pacote de horas não encontrado',
          },
          can: {
            not: {
              be: {
                edited: 'O Pacote de Horas não pode ser editado'
              }
            }
          },
          canceled: 'O pacote foi cancelado',
          expired: 'Horário solicitado posterior à expiração do pacote',
          pending: 'Seus pacotes ainda não tiveram os pagamentos confirmados. Aguarde antes de fazer novas reservas',
          does: {
            not: {
              exist: 'Pacote não está presente no consultório. É possível que o proprietário tenha alterado a publicação. Favor refazer a compra. Você não foi debitado'
            }
          }
        },
      },
      product: {
        not: {
          found: 'Produto não encontrado',
          available: {
            in: {
              space: 'Produto não disponível neste consultório'
            }
          }
        },
        owner: {
          canceled: 'Somente o dono do produto pode cancelá-lo',
        },
        space: {
          not: {
            found: 'Consultório referente ao produto não encontrado. Entrar com contato com suporte'
          }
        },
        canceled: 'O produto já está cancelado',
        invalid: 'Produto inválido',
        expired: 'Produto já expirou',
        add: 'Erro ao adicionar produto',
        user: {
          not: {
            found: 'Habitante referente ao produto não encontrado. Entrar com contato com suporte'
          }
        },
      },
      value: {
        must: {
          be: {
            positive: 'O valor não pode ser menor que 0.',
          }
        },
        invalid: 'Valor inválido'
      },
      receiver: {
        limit: 'Não é possível adicionar mais recebedores',
        balance: {
          exists: 'Este recebedor possui saldo. Transfira o saldo antes de removê-lo'
        },
        not: {
          found: 'Recebedor não encontrado'
        }
      },
      contract: {
        invalid: {
          start: {
            date: 'Data de início inválida',
          }
        },
        date: {
          limit: 'O início do contrato não pode ultrapassar 60 dias no futuro',
          past: 'O contrato deve-se iniciar no futuro',
        },
        expired: 'O contrato já se expirou',
        start: {
          past: 'Início do contrato deve ser hoje ou no futuro'
        },
        with: {
          conflicts: 'Há reservas no período do contrato. Tentar novamente'
        }
      },
      integral: {
        request: {
          not: {
            found: 'Solicitação não encontrada',
          },
          expired: 'Solicitação está expirada',
          approved: 'A solicitação já foi aprovada',
        },
        package: {
          not: {
            found: 'Pacote integral não encontrado',
            confirmed: 'Pacote não pode ser confirmado',
          }
        }
      },
      document: {
        not: {
          found: 'Documento não foi encontrado.'
        }
      },
      survey: {
        already: {
          completed: 'Pesquisa já foi respondida',
        },
        not: {
          found: 'Pesquisa não encontrada',
        },
        complete: 'Erro ao enviar respostas',
      },
      order: {
        not: {
          found: 'Ordem não encontrada',
          available: 'O pedido não está mais disponível',
        },
        expired: 'O pedido está expirado',
        no: {
          current: {
            event: 'Não foi possível encontrar a reserva atual',
          }
        },
      },
      payment: {
        not: {
          completed: 'Erro no pagamento. Você não foi debitado. Contate o suporte',
        },
        minimum: {
          value: 'Valor não pode ser menor do que R$ 5,00'
        }
      },
      package: {
        not: {
          found: 'Pacote não encontrado',         
        }
      },
      not: {
        saas: {
          user: 'Usuário não é um usuário válido'
        }
      },
      main: {
        bank: {
          not: {
            found: 'Banco não encontrado',
          }
        }
      },
      subdomain: {
        not: {
          found: 'Subdomínio não encontrado',
        }
      },
      welcome: {
        email: {
          already: {
            sent: 'Boas-vindas já enviado para e-mail',
          }
        }
      },
      import: {
        already: {
          running: 'Importação já em andamento',
        }
      },
      purchase: {
        can: {
          not: {
            be: {
              zero: 'Preço não pode ser zero'
            }
          }
        }
      },
      transfer: {
        not: {
          found: 'Erro na transferência',
          completed: 'Erro na transferência'
        },
        missing: {
          transaction: {
            id: 'ID da transação não encontrado',
          },
          order: {
            id: 'ID da transação não encontrado'
          }
        },
        order: {
          not: {
            found: 'Ordem de transferência não encontrada',
          },
          without: {
            user: 'Ordem de transferência sem usuário',
            reference: 'Transferência sem referência',
          },
        },
        completed: 'Transferência já concluída',
        pending: 'Transferência em processameto',
        in: {
          bank: {
            processing: 'Transferência está sendo processada pelo banco',
          }
        }
      }
    },
    menu: {
      adminDashBoard: 'Dashboard do administrador',
      subscriptions: 'Assinatura',
      spaces: 'Consultórios',
      coupons: 'Cupons',
      habitatPass: 'Habitat Pass',
      integralRequests: 'Habitat Próprio',
      transactions: 'Transações',
      saas: 'Saas',
      users: 'Usuários',
      validateUsers: 'Validar Usuários',
      validateAdvertisements: 'Validar Anúncios',
      finance: 'Financeiro',
      reports: 'Relatórios',
      blog: 'Blog',
      admin: 'Administradores',
      configs: 'Configurações',
      doctorDashboard: 'Painel do profissional',
      doctorCalendar: 'Calendário do profissional',
      doctorEvents: 'Reservas efetuadas',
      services: 'Serviços',
      myProducts: 'Meus produtos',
      ownerDashboard: 'Painel do proprietário',
      ownerCalendar: 'Calendário do proprietário',
      events: 'Reservas',
      myProperties: 'Minhas propriedades',
      opportunities: 'Oportunidades',
      marketplace: 'Marketplace',
      dashboard: 'Painel',
      team: 'Equipe',
      properties: 'Propriedades',
      saasUsers: 'Habitantes',
      calendar: 'Calendário',
    },
  }
}
